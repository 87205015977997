import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { 
  Card, Typography, Box, Divider, Grid, CircularProgress, Radio, RadioGroup, 
  FormControlLabel, FormControl, FormLabel, Button, Container, Paper,
  Tabs, Tab, Alert
} from '@mui/material';
import { collection, getDocs, query, orderBy, addDoc, doc, updateDoc, getDoc, where, limit } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  borderRadius: 0,
  overflow: 'visible',
  position: 'relative',
  background: 'linear-gradient(180deg, rgba(26,61,143,0.02) 0%, rgba(26,61,143,0.05) 100%)',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiFormLabel-root': {
    color: theme.brandColors.blue,
    fontWeight: 600,
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    }
  },
  '& .MuiRadio-root': {
    color: theme.brandColors.blue,
    padding: theme.spacing(1.5),
    '&.Mui-checked': {
      color: theme.brandColors.blue,
    }
  },
  '& .MuiFormControlLabel-root': {
    marginBottom: theme.spacing(1),
  },
  '& .MuiFormControlLabel-label': {
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.95rem',
    }
  }
}));

const CategoryCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.08)',
  },
}));

function GuestMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [numGuests, setNumGuests] = useState(1);
  const [tourDate, setTourDate] = useState('');
  const [bookingId, setBookingId] = useState('');
  const [tourType, setTourType] = useState('');
  const [guestChoices, setGuestChoices] = useState({});
  const [menuData, setMenuData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentGuest, setCurrentGuest] = useState(0);
  const [validationError, setValidationError] = useState('');
  const [menuAlreadyCompleted, setMenuAlreadyCompleted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const bookingId = queryParams.get('bookingId') || '';
    const name = decodeURIComponent(queryParams.get('name') || '');
    const email = decodeURIComponent(queryParams.get('email') || '').replace('%40', '@');
    const guests = parseInt(queryParams.get('guests') || '1', 10);
    const date = queryParams.get('date') || '';
    const urlTourType = queryParams.get('tourType') || '';
    
    setBookingId(bookingId);
    setCustomerName(name);
    setCustomerEmail(email);
    setNumGuests(guests);
    setTourDate(date);
    setTourType(urlTourType);
    
    // Store tourType in sessionStorage to persist between refreshes
    if (urlTourType) {
      sessionStorage.setItem('currentTourType', urlTourType);
    }
    
    // ALWAYS check Firestore directly to see if this booking already submitted choices
    if (bookingId) {
      checkCompletedStatus(bookingId);
    }
  }, []);

  // This useEffect will run after the component mounts to recover tourType from sessionStorage
  useEffect(() => {
    // If tourType is empty but we have it in sessionStorage, use that value
    if (!tourType && sessionStorage.getItem('currentTourType')) {
      setTourType(sessionStorage.getItem('currentTourType'));
    }
  }, [tourType]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        console.log(`Fetching menu data for tour type: ${tourType}`);
        
        // Skip fetching data if no tourType is provided
        if (!tourType) {
          console.log('No tour type specified, skipping data fetch');
          setLoading(false);
          return;
        }
        
        // Use hardcoded data directly based on tour type
        let processedData = {};
        
        if (tourType === 'beach-escape') {
          // Only Beach Escape menu items
          processedData = {
            'Beach Escape': {
              'Food': [
                { name: 'Caesar Salad', description: 'Classic salad with romaine lettuce, croutons and Caesar dressing' },
                { name: 'Burger', description: 'Beef patty with lettuce, tomato and special sauce' },
                { name: 'Cheese Burger', description: 'Beef patty with cheese, lettuce, tomato and special sauce' },
                { name: 'Chicken Sate', description: 'Grilled chicken skewers with peanut sauce' }
              ],
              'Drinks': [
                { name: 'Cola', description: '' },
                { name: 'Sprite', description: '' },
                { name: 'Water', description: '' }
              ]
            }
          };
        } 
        else if (tourType === 'island-tour') {
          // Only Island Tour menu items
          processedData = {
            'Island Tour': {
              'Food': [
                { name: 'Truffle Tagliatelle (V)', description: 'Vegetarian pasta with truffle sauce' },
                { name: 'Karni Stobá', description: 'Traditional Curaçaoan beef stew slow-cooked with local vegetables and spices' },
                { name: 'Galiña Stobá', description: 'Traditional Curaçaoan chicken stew with local vegetables and herbs' },
                { name: 'Vegie Stobá', description: 'Vegetarian version of the traditional Curaçaoan stew with local vegetables' }
              ]
            }
          };
        } 
        else if (tourType === 'culinary-tour') {
          // Only Culinary Tour menu items
          processedData = {
            'Rustiq': {
              'Appetizer': [
                { name: 'Mini beef pastechi', description: 'Local pastry filled with seasoned beef' },
                { name: 'Coconut Rice', description: 'Rice cooked in coconut milk with local spices' },
                { name: 'Pumpkin Soup', description: 'Creamy soup made with local pumpkin' }
              ],
              'Cocktail': [
                { name: 'Mosa Nena', description: 'Local cocktail with rum and tropical fruits' },
                { name: 'Buchi Fil', description: 'Traditional blue curaçao cocktail' },
                { name: 'Kura di Shon', description: 'Signature cocktail with local ingredients' }
              ]
            },
            'Gouverneur': {
              'Main Course': [
                { name: 'Fettucini Truffle', description: 'Pasta with truffle cream sauce' },
                { name: 'Cuban Banana Soup (Creamy, Slightly spicy)', description: 'Rich soup made with plantains and local spices' },
                { name: 'Canneloni (Salmon, Spinach, Ricotta)', description: 'Pasta rolls filled with salmon, spinach and ricotta cheese' },
                { name: 'Karni Stobá', description: 'Traditional beef stew slow-cooked with local herbs and vegetables' },
                { name: 'Antillian Beefstew', description: 'Local style beef stew with Caribbean spices and vegetables' },
                { name: 'Caribbean Chicken (Fillet marinated with spices, Mango Chutney)', description: 'Chicken fillet marinated in island spices served with mango chutney' }
              ],
              'Drink': [
                { name: 'Soft drink', description: '' },
                { name: 'Juice', description: 'Fresh tropical fruit juice' },
                { name: 'Water / Sparkling water', description: '' }
              ]
            },
            'Gondola Restaurant': {
              'Dessert': [
                { name: 'Quesillo', description: 'Traditional Curaçaoan caramel flan' },
                { name: 'With Tea or Coffee', description: '' }
              ]
            }
          };
        } 
        else {
          console.log('Unrecognized tour type, no menu data will be shown');
          processedData = {}; // Empty object when tour type is not recognized
        }

        console.log('Final processed menu data:', processedData);
        setMenuData(processedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching menu data: ', error);
        setLoading(false);
      }
    };

    fetchMenuData();
  }, [tourType]);

  useEffect(() => {
    const initializeGuestChoices = () => {
      const choices = {};
      for (let i = 1; i <= numGuests; i++) {
        choices[`Guest ${i}`] = {};
      }
      setGuestChoices(choices);
    };

    initializeGuestChoices();
  }, [numGuests]);

  const isGuestComplete = (guestNumber) => {
    const guestData = guestChoices[guestNumber];
    if (!guestData) return false;

    // Check if all restaurants and categories have selections
    return Object.entries(menuData).every(([restaurant, categories]) => {
      return Object.keys(categories).every(category => {
        return guestData[restaurant]?.[category];
      });
    });
  };

  const areAllGuestsComplete = () => {
    return Object.keys(guestChoices).every(guest => isGuestComplete(guest));
  };

  const handleGuestChoice = (guestNumber, restaurant, category, option) => {
    setGuestChoices(prev => ({
      ...prev,
      [guestNumber]: {
        ...prev[guestNumber],
        [restaurant]: {
          ...prev[guestNumber][restaurant],
          [category]: option
        }
      }
    }));
    setValidationError('');
  };

  const handleSubmitChoices = async () => {
    if (!areAllGuestsComplete()) {
      setValidationError('Please complete menu selections for all guests before submitting.');
      return;
    }

    // Double-check Firestore first to make sure this booking hasn't already submitted
    try {
      const choicesQuery = query(
        collection(db, 'guestChoices'),
        where('bookingId', '==', bookingId),
        limit(1)
      );
      
      const choicesSnap = await getDocs(choicesQuery);
      
      if (!choicesSnap.empty) {
        setMenuAlreadyCompleted(true);
        setValidationError('This booking has already submitted menu choices. Please contact us if you need to make changes.');
        return;
      }
    } catch (error) {
      console.error('Error checking if booking already submitted:', error);
    }

    try {
      // Get menuRequestId from URL if available
      const queryParams = new URLSearchParams(window.location.search);
      const menuRequestId = queryParams.get('menuRequestId');

      const choicesData = {
        bookingId,
        numGuests,
        tourDate,
        tourType,
        customerName,
        customerEmail,
        guestChoices,
        submittedAt: new Date()
      };
      
      // Save the guest choices to Firestore
      const docRef = await addDoc(collection(db, 'guestChoices'), choicesData);
      console.log('Saved menu choices with ID:', docRef.id);
      
      // If we have a menuRequestId, update the request status to completed
      if (menuRequestId) {
        try {
          const menuRequestRef = doc(db, 'menuRequests', menuRequestId);
          await updateDoc(menuRequestRef, {
            status: 'completed',
            completedAt: new Date(),
            menuSelections: guestChoices
          });
          console.log(`Updated menu request ${menuRequestId} as completed`);
        } catch (error) {
          console.error('Error updating menu request status:', error);
          // Continue anyway since the choices were saved
        }
      }
      
      // Force disable the submit button immediately
      const submitButton = document.getElementById('submit-menu-button');
      if (submitButton) {
        submitButton.disabled = true;
      }
      
      // Set menuAlreadyCompleted to true after successful submission
      setMenuAlreadyCompleted(true);
      
      // Show thank you message instead of alert
      setShowThankYou(true);
    } catch (error) {
      console.error('Error submitting choices: ', error);
      setValidationError('Error submitting choices. Please try again.');
    }
  };

  const handleChangeGuest = (event, newValue) => {
    setCurrentGuest(newValue);
  };

  // Completely rewritten function to aggressively check Firestore completion status
  const checkCompletedStatus = async (bookingId) => {
    if (!bookingId) return;
    
    try {
      console.log(`Checking if booking ${bookingId} is already completed...`);
      
      // Query the guestChoices collection for this specific bookingId
      const choicesQuery = query(
        collection(db, 'guestChoices'),
        where('bookingId', '==', bookingId),
        orderBy('submittedAt', 'desc'),
        limit(1)
      );
      
      const choicesSnap = await getDocs(choicesQuery);
      
      if (!choicesSnap.empty) {
        // Get data from the most recent submission
        const choiceData = choicesSnap.docs[0].data();
        console.log('Found completed menu for booking:', bookingId);
        console.log('Submission data:', choiceData);
        
        // Set existing choices
        setGuestChoices(choiceData.guestChoices || {});
        
        // Force menuAlreadyCompleted to true
        setMenuAlreadyCompleted(true);
        
        // Always disable the submit button for completed bookings
        const submitButton = document.getElementById('submit-menu-button');
        if (submitButton) {
          submitButton.disabled = true;
        }
      } else {
        console.log('No previous submissions found for booking:', bookingId);
        setMenuAlreadyCompleted(false);
      }
    } catch (error) {
      console.error('Error checking booking completion status:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        background: 'linear-gradient(180deg, rgba(26,61,143,0.02) 0%, rgba(26,61,143,0.05) 100%)',
      }}>
        <CircularProgress sx={{ color: theme.brandColors.blue }} />
      </Box>
    );
  }

  const guestNumbers = Object.keys(guestChoices);
  const currentGuestNumber = guestNumbers[currentGuest];

  return (
    <StyledCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Container maxWidth="lg" disableGutters={isMobile}>
        <ContentBox>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography 
              variant="h4" 
              sx={{ 
                color: theme.brandColors.blue,
                fontWeight: 800,
                mb: { xs: 2, md: 4 },
                fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2.25rem' },
                letterSpacing: '-0.02em',
                textAlign: 'center',
                px: { xs: 2, sm: 0 }
              }}
            >
              Menu Selection
            </Typography>

            {/* Thank You Message */}
            {showThankYou && (
              <Paper 
                elevation={0}
                component={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
                sx={{ 
                  p: { xs: 3, sm: 4, md: 5 },
                  mb: { xs: 4, md: 6 },
                  mx: { xs: 2, sm: 0 },
                  backgroundColor: 'rgba(255, 252, 235, 0.98)',
                  borderRadius: theme.shape.borderRadius,
                  border: `1px solid ${theme.brandColors.lightYellow}`,
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.08)'
                }}
              >
                <Box sx={{ 
                  position: 'absolute',
                  top: '-20px',
                  left: '-20px',
                  width: '120px',
                  height: '120px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(255, 230, 0, 0.15)',
                  zIndex: 0
                }} />
                
                <Box sx={{ 
                  position: 'absolute',
                  bottom: '-25px',
                  right: '-25px',
                  width: '150px',
                  height: '150px',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(26, 61, 143, 0.05)',
                  zIndex: 0
                }} />
                
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <CheckCircleIcon 
                    sx={{ 
                      fontSize: { xs: 56, md: 70 }, 
                      color: theme.brandColors.blue,
                      mb: 2
                    }} 
                  />
                  
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      color: theme.brandColors.blue,
                      fontWeight: 800,
                      mb: 2,
                      fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                    }}
                  >
                    Thank You!
                  </Typography>
                  
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: theme.brandColors.blue,
                      fontSize: { xs: '1rem', md: '1.1rem' },
                      mb: 3,
                      mx: 'auto',
                      maxWidth: '600px'
                    }}
                  >
                    Your menu selections have been successfully submitted. We look forward to welcoming you to Curaçao and hope you enjoy your {tourType.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} with us!
                  </Typography>
                  
                  <Box sx={{ 
                    p: 2, 
                    mb: 2, 
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', 
                    display: 'inline-block'
                  }}>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        color: theme.brandColors.blue,
                        opacity: 0.8,
                        fontStyle: 'italic'
                      }}
                    >
                      We've sent a confirmation email to {customerEmail}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            )}
            
            {!showThankYou && (
              <>
                <Paper elevation={0} sx={{ 
                  p: { xs: 2, sm: 3, md: 4 },
                  mb: { xs: 2, md: 4 },
                  mx: { xs: 2, sm: 0 },
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  borderRadius: theme.shape.borderRadius,
                }}>
                  <Typography 
                    sx={{ 
                      color: theme.brandColors.blue,
                      fontWeight: 600,
                      mb: 2,
                      fontSize: { xs: '1.1rem', md: '1.2rem' }
                    }}
                  >
                    Booking Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ 
                        color: theme.brandColors.blue,
                        opacity: 0.9,
                        fontSize: '0.95rem'
                      }}>
                        <strong>Guest:</strong> {customerName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ 
                        color: theme.brandColors.blue,
                        opacity: 0.9,
                        fontSize: '0.95rem'
                      }}>
                        <strong>Email:</strong> {customerEmail}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ 
                        color: theme.brandColors.blue,
                        opacity: 0.9,
                        fontSize: '0.95rem'
                      }}>
                        <strong>Tour Date:</strong> {new Date(tourDate).toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ 
                        color: theme.brandColors.blue,
                        opacity: 0.9,
                        fontSize: '0.95rem'
                      }}>
                        <strong>Number of Guests:</strong> {numGuests}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>

                {menuAlreadyCompleted && (
                  <Alert 
                    severity="success" 
                    sx={{ 
                      mx: { xs: 2, sm: 0 }, 
                      mb: 3,
                      '& .MuiAlert-message': {
                        color: '#1e4620'
                      }
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      You have already completed your menu selections!
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Your menu choices have been saved and can be viewed below. If you need to make changes, please contact us directly.
                    </Typography>
                  </Alert>
                )}

                <Box sx={{ 
                  borderBottom: 1, 
                  borderColor: 'divider',
                  mx: { xs: 2, sm: 0 },
                  mb: 2
                }}>
                  <Tabs 
                    value={currentGuest} 
                    onChange={handleChangeGuest} 
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      '& .MuiTab-root': {
                        color: theme.brandColors.blue,
                        opacity: 0.7,
                        '&.Mui-selected': {
                          color: theme.brandColors.blue,
                          opacity: 1,
                        }
                      },
                      '& .MuiTabs-indicator': {
                        backgroundColor: theme.brandColors.blue,
                      }
                    }}
                  >
                    {guestNumbers.map((guestNumber, index) => (
                      <Tab 
                        key={guestNumber} 
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {guestNumber}
                            {isGuestComplete(guestNumber) && (
                              <CheckCircleIcon sx={{ color: theme.brandColors.blue, fontSize: 16 }} />
                            )}
                          </Box>
                        } 
                      />
                    ))}
                  </Tabs>
                </Box>

                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentGuest}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Paper elevation={0} sx={{ 
                      p: { xs: 2, sm: 3, md: 4 },
                      mb: { xs: 2, md: 4 },
                      mx: { xs: 2, sm: 0 },
                      backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      borderRadius: theme.shape.borderRadius,
                    }}>
                      {Object.entries(menuData).map(([restaurant, categories]) => (
                        <Box key={restaurant} sx={{ mb: { xs: 3, md: 4 } }}>
                          <Typography 
                            sx={{ 
                              color: theme.brandColors.blue,
                              fontWeight: 600,
                              mb: { xs: 1.5, md: 2 },
                              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                              textAlign: { xs: 'center', sm: 'left' }
                            }}
                          >
                            {restaurant}
                          </Typography>

                          {Object.entries(categories).map(([category, items]) => (
                            <CategoryCard key={category}>
                              <StyledFormControl>
                                <FormLabel sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                                  {category}
                                </FormLabel>
                                <RadioGroup
                                  value={guestChoices[currentGuestNumber]?.[restaurant]?.[category] || ''}
                                  onChange={(e) => handleGuestChoice(currentGuestNumber, restaurant, category, e.target.value)}
                                >
                                  {items.map((item, index) => (
                                    <FormControlLabel
                                      key={index}
                                      value={item.name}
                                      control={
                                        <Radio 
                                          sx={{ 
                                            '& .MuiSvgIcon-root': {
                                              fontSize: { xs: 20, sm: 24 }
                                            }
                                          }} 
                                        />
                                      }
                                      label={
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                          {item.name}
                                          {item.description && (
                                            <Typography 
                                              variant="caption" 
                                              sx={{ 
                                                color: theme.brandColors.blue,
                                                opacity: 0.7,
                                                fontSize: { xs: '0.75rem', md: '0.8rem' },
                                                fontStyle: 'italic'
                                              }}
                                            >
                                              {item.description}
                                            </Typography>
                                          )}
                                        </Box>
                                      }
                                    />
                                  ))}
                                </RadioGroup>
                              </StyledFormControl>
                            </CategoryCard>
                          ))}
                        </Box>
                      ))}
                    </Paper>
                  </motion.div>
                </AnimatePresence>

                {validationError && (
                  <Alert 
                    severity="error" 
                    sx={{ 
                      mx: { xs: 2, sm: 0 }, 
                      mb: 2,
                      '& .MuiAlert-message': {
                        color: '#5f2120'
                      }
                    }}
                  >
                    {validationError}
                  </Alert>
                )}

                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'center',
                  mt: { xs: 2, md: 4 },
                  mb: { xs: 3, md: 5 },
                  px: { xs: 2, sm: 0 }
                }}>
                  <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ width: '100%' }}>
                    <Button
                      variant="contained"
                      onClick={handleSubmitChoices}
                      fullWidth={isMobile}
                      disabled={menuAlreadyCompleted}
                      id="submit-menu-button"
                      sx={{
                        backgroundColor: menuAlreadyCompleted ? 'rgba(26,61,143,0.05)' : 
                                        (areAllGuestsComplete() ? theme.brandColors.yellow : 'rgba(26,61,143,0.1)'),
                        color: theme.brandColors.blue,
                        fontWeight: 700,
                        px: { xs: 3, md: 6 },
                        py: { xs: 2, md: 2 },
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        borderRadius: 2,
                        textTransform: 'none',
                        boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          backgroundColor: menuAlreadyCompleted ? 'rgba(26,61,143,0.05)' :
                                          (areAllGuestsComplete() ? theme.brandColors.lightYellow : 'rgba(26,61,143,0.15)'),
                          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                        }
                      }}
                    >
                      {menuAlreadyCompleted ? 'Menu Choices Submitted' : 'Submit Menu Choices'}
                    </Button>
                  </motion.div>
                </Box>
              </>
            )}
          </motion.div>
        </ContentBox>
      </Container>
    </StyledCard>
  );
}

export default GuestMenu;