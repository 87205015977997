import React from 'react';
import { Card, Typography, Box, Divider, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: 600,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  position: 'relative',
  background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  padding: theme.spacing(4),
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(6),
  },
}));

const MenuItemPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  transition: 'all 0.3s ease',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0,0,0,0.08)',
  },
}));

function Menuform({ savedMenu }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formatDate = (date) => {
    if (!date) return '';
    const dateObj = date.toDate ? date.toDate() : date;
    return dateObj.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (!savedMenu) {
    return (
      <StyledCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <ContentBox>
          <Typography 
            variant="h5" 
            sx={{ 
              color: theme.brandColors.blue,
              fontWeight: 800,
              mb: { xs: 3, md: 4 },
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
              letterSpacing: '-0.02em',
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            Menu Preview
          </Typography>
          <Typography 
            sx={{ 
              color: theme.brandColors.blue,
              opacity: 0.7,
              textAlign: { xs: 'center', sm: 'left' },
              fontSize: { xs: '1rem', md: '1.1rem' }
            }}
          >
            Your menu will appear here after saving
          </Typography>
        </ContentBox>
      </StyledCard>
    );
  }

  return (
    <StyledCard component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <ContentBox>
        <Typography 
          variant="h5" 
          sx={{ 
            color: theme.brandColors.blue,
            fontWeight: 800,
            mb: { xs: 2, md: 3 },
            fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
            letterSpacing: '-0.02em',
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          Menu Preview
        </Typography>

        <Box sx={{ mb: { xs: 3, md: 4 } }}>
          <Typography 
            sx={{ 
              color: theme.brandColors.blue,
              mb: 1,
              fontWeight: 600,
              fontSize: { xs: '1.1rem', md: '1.2rem' },
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            {savedMenu.tourType && (
              <Box component="span" sx={{ display: 'block', mb: 0.5 }}>
                {savedMenu.tourType}
              </Box>
            )}
            {savedMenu.restaurantName}
          </Typography>
          <Typography 
            sx={{ 
              color: theme.brandColors.blue,
              opacity: 0.8,
              mb: 1,
              fontSize: { xs: '1rem', md: '1.1rem' },
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            {savedMenu.category}
          </Typography>
          <Typography 
            sx={{ 
              color: theme.brandColors.blue,
              opacity: 0.7,
              fontSize: { xs: '0.9rem', md: '1rem' },
              textAlign: { xs: 'center', sm: 'left' }
            }}
          >
            Guest: {savedMenu.guestName}
          </Typography>
        </Box>

        <Divider sx={{ 
          my: { xs: 3, md: 4 }, 
          borderColor: theme.brandColors.blue,
          opacity: 0.3 
        }} />

        <Typography 
          variant="subtitle1" 
          sx={{ 
            mb: { xs: 2.5, md: 3 },
            color: theme.brandColors.blue,
            fontWeight: 600,
            fontSize: { xs: '1.1rem', md: '1.2rem' },
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          Menu Items:
        </Typography>

        <Box sx={{ mb: { xs: 2.5, md: 3 } }}>
          {savedMenu.menuItems.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <MenuItemPaper elevation={1}>
                <Typography 
                  sx={{ 
                    color: theme.brandColors.blue,
                    fontWeight: 600,
                    fontSize: { xs: '0.9rem', md: '1rem' }
                  }}
                >
                  {item.name}
                </Typography>
                {item.description && (
                  <Typography 
                    sx={{ 
                      color: theme.brandColors.blue,
                      opacity: 0.8,
                      fontSize: { xs: '0.8rem', md: '0.9rem' },
                      mt: 0.5,
                      fontStyle: 'italic'
                    }}
                  >
                    {item.description}
                  </Typography>
                )}
              </MenuItemPaper>
            </motion.div>
          ))}
        </Box>

        <Box sx={{ 
          mt: 'auto',
          pt: { xs: 2.5, md: 3 },
          borderTop: `1px solid rgba(26,61,143,0.2)`,
          textAlign: 'right'
        }}>
          <Typography 
            sx={{ 
              color: theme.brandColors.blue,
              opacity: 0.7,
              fontSize: { xs: '0.8rem', md: '0.9rem' }
            }}
          >
            Created: {formatDate(savedMenu.createdAt)}
          </Typography>
        </Box>
      </ContentBox>
    </StyledCard>
  );
}

export default Menuform;