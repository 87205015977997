import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Chip,
  IconButton,
  Tooltip,
  Button,
  Typography,
  CircularProgress
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Email as EmailIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { format, parseISO, isValid } from 'date-fns';

const WaiverTable = ({ 
  waivers, 
  loading, 
  onViewWaiver, 
  onResendEmail, 
  onMarkCompleted, 
  onDeleteWaiver,
  onLoadMore,
  hasMore,
  defaultSortField,
  defaultSortDirection
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortField, setSortField] = useState(defaultSortField || 'createdAt');
  const [sortDirection, setSortDirection] = useState(defaultSortDirection || 'desc');

  // When default sort changes, update local state
  useEffect(() => {
    if (defaultSortField) {
      setSortField(defaultSortField);
    }
    if (defaultSortDirection) {
      setSortDirection(defaultSortDirection);
    }
  }, [defaultSortField, defaultSortDirection]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const getSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      // If date is a Firestore Timestamp
      if (date && date._seconds) {
        return format(new Date(date._seconds * 1000), 'MMM d, yyyy h:mm a');
      }
      
      // Try creating a Date object
      const dateObj = new Date(date);
      if (isValid(dateObj)) {
        return format(dateObj, 'MMM d, yyyy h:mm a');
      }
      
      return 'Invalid Date';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  // Function to safely display tour date
  const formatTourDate = (tourDate) => {
    if (!tourDate) return 'N/A';
    
    try {
      // If date is already in readable format (e.g. "Saturday, March 1, 2025")
      if (typeof tourDate === 'string' && tourDate.includes(',')) {
        return tourDate;
      }

      // Try parsing as YYYY-MM-DD format
      const dateParts = tourDate.split('-');
      if (dateParts.length === 3) {
        const dateObj = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
        if (isValid(dateObj)) {
          return format(dateObj, 'EEEE, MMMM d, yyyy');
        }
      }

      // Try parsing as ISO date string
      const parsedDate = parseISO(tourDate);
      if (isValid(parsedDate)) {
        return format(parsedDate, 'EEEE, MMMM d, yyyy');
      }

      // Try creating a Date object directly
      const dateObj = new Date(tourDate);
      if (isValid(dateObj)) {
        return format(dateObj, 'EEEE, MMMM d, yyyy');
      }

      console.warn('Invalid date format:', tourDate);
      return 'Invalid Date';
    } catch (error) {
      console.error('Error formatting tour date:', error);
      return 'Invalid Date';
    }
  };

  const getStatusChip = (status) => {
    switch (status) {
      case 'pending':
        return <Chip label="Pending" color="warning" size="small" />;
      case 'completed':
        return <Chip label="Completed" color="success" size="small" />;
      case 'failed':
        return <Chip label="Failed" color="error" size="small" />;
      default:
        return <Chip label={status} size="small" />;
    }
  };

  // Sort waivers based on current sort field and direction
  const sortedWaivers = [...waivers].sort((a, b) => {
    let aValue = a[sortField];
    let bValue = b[sortField];
    
    // Handle dates
    if (sortField === 'createdAt' || sortField === 'emailSentAt' || sortField === 'completedAt') {
      aValue = aValue ? new Date(aValue).getTime() : 0;
      bValue = bValue ? new Date(bValue).getTime() : 0;
    }
    
    // Special handling for tourDate (which is a string in various formats)
    if (sortField === 'tourDate') {
      // If we have parsed date objects available from WaiverDashboard
      if (a.tourDateObj && b.tourDateObj) {
        aValue = a.tourDateObj.getTime();
        bValue = b.tourDateObj.getTime();
      } else {
        // Try to parse the dates from strings
        try {
          // Parse strings like "Saturday, March 1, 2025"
          const parseDate = (dateStr) => {
            if (!dateStr || typeof dateStr !== 'string') return 0;
            if (dateStr === 'Invalid Date' || dateStr === 'TBD') return 0;
            
            const dateParts = dateStr.replace(',', '').split(' ');
            if (dateParts.length >= 3) {
              const [dayOfWeek, month, day, year] = dateParts;
              const monthIndex = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
              ].findIndex(m => m.startsWith(month));
              
              if (monthIndex !== -1 && !isNaN(parseInt(day)) && !isNaN(parseInt(year))) {
                return new Date(parseInt(year), monthIndex, parseInt(day)).getTime();
              }
            }
            return 0;
          };
          
          aValue = parseDate(aValue);
          bValue = parseDate(bValue);
        } catch (e) {
          console.warn('Error sorting tour dates:', e);
          aValue = 0;
          bValue = 0;
        }
      }
    }
    
    // Handle strings
    if (typeof aValue === 'string') {
      aValue = aValue.toLowerCase();
    }
    if (typeof bValue === 'string') {
      bValue = bValue.toLowerCase();
    }
    
    if (sortDirection === 'asc') {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });

  // Paginate waivers
  const paginatedWaivers = sortedWaivers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="waiverTable">
          <TableHead>
            <TableRow>
              <TableCell 
                onClick={() => handleSort('participantName')}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Participant
                  {getSortIcon('participantName')}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSort('tourName')}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Tour
                  {getSortIcon('tourName')}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSort('tourDate')}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Tour Date
                  {getSortIcon('tourDate')}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSort('createdAt')}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Created
                  {getSortIcon('createdAt')}
                </Box>
              </TableCell>
              <TableCell 
                onClick={() => handleSort('status')}
                sx={{ cursor: 'pointer', fontWeight: 'bold' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Status
                  {getSortIcon('status')}
                </Box>
              </TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && waivers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  Loading waiver requests...
                </TableCell>
              </TableRow>
            ) : paginatedWaivers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ py: 3 }}>
                  No waiver requests found
                </TableCell>
              </TableRow>
            ) : (
              paginatedWaivers.map((waiver) => (
                <TableRow key={waiver.id} hover>
                  <TableCell>
                    <Box>
                      <Typography variant="body2" fontWeight="medium">
                        {waiver.participantName || 'N/A'}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {waiver.participantEmail}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box>
                      <Typography variant="body2">
                        {waiver.tourName || 'N/A'}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        Booking: {waiver.bookingNumber || 'N/A'}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{formatTourDate(waiver.tourDate)}</TableCell>
                  <TableCell>{formatDate(waiver.createdAt)}</TableCell>
                  <TableCell>{getStatusChip(waiver.status)}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="View Details">
                      <IconButton 
                        size="small" 
                        onClick={() => onViewWaiver(waiver)}
                        color="primary"
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    
                    {waiver.status === 'pending' && (
                      <>
                        <Tooltip title="Resend Email">
                          <IconButton 
                            size="small" 
                            onClick={() => onResendEmail(waiver)}
                            color="primary"
                          >
                            <EmailIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark as Completed">
                          <IconButton 
                            size="small" 
                            onClick={() => onMarkCompleted(waiver)}
                            color="success"
                          >
                            <CheckCircleIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    
                    <Tooltip title="Delete">
                      <IconButton 
                        size="small" 
                        onClick={() => onDeleteWaiver(waiver)}
                        color="error"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2, py: 1 }}>
        <Box>
          {hasMore && (
            <Button 
              onClick={onLoadMore}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          )}
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={waivers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default WaiverTable; 