import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Tabs,
  Tab,
  TextField,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import {
  Search as SearchIcon,
  Refresh as RefreshIcon,
  PieChart as PieChartIcon,
  ViewList as ViewListIcon,
  Close as CloseIcon,
  CalendarToday as CalendarTodayIcon,
  Visibility as VisibilityIcon,
  Email as EmailIcon,
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon
} from '@mui/icons-material';
import { collection, query, where, orderBy, limit, getDocs, startAfter, Timestamp, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import { format, isAfter, isBefore, parseISO, addDays, subDays, isValid } from 'date-fns';
import { toast } from 'react-hot-toast';
import WaiverDetailView from './WaiverDetailView';
import WaiverStatsCards from './WaiverStatsCards';
import WaiverFilterBar from './WaiverFilterBar';
import WaiverTable from './WaiverTable';
import WaiverCharts from './WaiverCharts';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`waiver-tabpanel-${index}`}
      aria-labelledby={`waiver-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const WaiverDashboard = () => {
  const [waiverRequests, setWaiverRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [tourFilter, setTourFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [tourDateStartFilter, setTourDateStartFilter] = useState(null);
  const [tourDateEndFilter, setTourDateEndFilter] = useState(null);
  const [defaultSortField, setDefaultSortField] = useState('tourDate');
  const [defaultSortDirection, setDefaultSortDirection] = useState('desc');
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedWaiver, setSelectedWaiver] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [stats, setStats] = useState({
    total: 0,
    completed: 0,
    pending: 0,
    failed: 0,
    completionRate: 0,
    lastWeek: 0,
    pendingOlderThan48h: 0,
    tourStats: [],
    dailyStats: []
  });
  const [uniqueTours, setUniqueTours] = useState([]);
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'success' });
  const theme = useMediaQuery(useTheme().breakpoints.down('md'));
  const [selectedTours, setSelectedTours] = useState([]);
  const [upcomingWaivers, setUpcomingWaivers] = useState({
    completed: [],
    uncompleted: []
  });
  const [filteredRequests, setFilteredRequests] = useState([]);

  useEffect(() => {
    fetchWaiverRequests();
  }, [statusFilter, tourFilter, startDateFilter, endDateFilter, tourDateStartFilter, tourDateEndFilter]);

  useEffect(() => {
    if (waiverRequests.length > 0) {
      calculateStats();
      extractUniqueTours();
      organizeUpcomingWaivers();
    }
  }, [waiverRequests]);

  const fetchWaiverRequests = async () => {
    setLoading(true);
    try {
      const waiverRequestsRef = collection(db, 'waiverRequests');
      let queryConstraints = [];

      // Add filters based on user selection
      if (statusFilter !== 'all') {
        queryConstraints.push(where('status', '==', statusFilter));
      }
      
      if (tourFilter) {
        queryConstraints.push(where('tourId', '==', tourFilter));
      }

      // Add date range filter if selected
      if (tourDateStartFilter) {
        queryConstraints.push(where('tourDate', '>=', format(tourDateStartFilter, 'yyyy-MM-dd')));
      }
      if (tourDateEndFilter) {
        queryConstraints.push(where('tourDate', '<=', format(tourDateEndFilter, 'yyyy-MM-dd')));
      }

      // Always order by tourDate and then by createdAt
      queryConstraints.push(orderBy('tourDate', 'asc'));
      queryConstraints.push(orderBy('createdAt', 'desc'));

      const q = query(waiverRequestsRef, ...queryConstraints);
      const snapshot = await getDocs(q);
      
      const requests = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        tourDateObj: doc.data().tourDate ? new Date(doc.data().tourDate) : null
      }));

      setWaiverRequests(requests);
      setFilteredRequests(requests);
      calculateStats();
      extractUniqueTours();
      organizeUpcomingWaivers();
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching waiver requests:', error);
      setLoading(false);
      setAlertInfo({
        open: true,
        message: 'Error fetching waiver requests',
        severity: 'error'
      });
    }
  };

  const calculateStats = () => {
    const total = waiverRequests.length;
    const completed = waiverRequests.filter(req => req.status === 'completed').length;
    const pending = total - completed;
    const completionRate = total > 0 ? Math.round((completed / total) * 100) : 0;
    
    // Calculate stats by tour
    const tourMap = new Map();
    waiverRequests.forEach(req => {
      if (!tourMap.has(req.tourName)) {
        tourMap.set(req.tourName, { total: 0, completed: 0, pending: 0 });
      }
      
      const tourStat = tourMap.get(req.tourName);
      tourStat.total += 1;
      
      if (req.status === 'completed') {
        tourStat.completed += 1;
      } else {
        tourStat.pending += 1;
      }
    });
    
    const tourStats = Array.from(tourMap.entries()).map(([tourName, stats]) => ({
      tourName,
      total: stats.total,
      completed: stats.completed,
      pending: stats.pending,
      completionRate: Math.round((stats.completed / stats.total) * 100)
    }));
    
    // Calculate daily stats for the last 7 days
    const dailyMap = new Map();
    const today = new Date();
    
    // Initialize the map with the last 7 days
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const dateStr = format(date, 'yyyy-MM-dd');
      dailyMap.set(dateStr, { date: dateStr, sent: 0, completed: 0 });
    }
    
    // Fill in the data
    waiverRequests.forEach(req => {
      try {
        if (req.createdAt) {
          // Handle Firestore timestamp
          const createdDate = req.createdAt._seconds 
            ? new Date(req.createdAt._seconds * 1000) 
            : new Date(req.createdAt);
            
          if (isValid(createdDate)) {
            const dateStr = format(createdDate, 'yyyy-MM-dd');
            if (dailyMap.has(dateStr)) {
              const dailyStat = dailyMap.get(dateStr);
              dailyStat.sent += 1;
            }
          }
        }
        
        if (req.completedAt) {
          // Handle Firestore timestamp
          const completedDate = req.completedAt._seconds 
            ? new Date(req.completedAt._seconds * 1000) 
            : new Date(req.completedAt);
            
          if (isValid(completedDate)) {
            const dateStr = format(completedDate, 'yyyy-MM-dd');
            if (dailyMap.has(dateStr)) {
              const dailyStat = dailyMap.get(dateStr);
              dailyStat.completed += 1;
            }
          }
        }
      } catch (error) {
        console.error('Error processing dates in calculateStats:', error, req);
      }
    });
    
    const dailyStats = Array.from(dailyMap.values());
    
    setStats({
      total,
      completed,
      pending,
      completionRate,
      tourStats,
      dailyStats
    });
  };

  const extractUniqueTours = () => {
    const tourSet = new Set();
    waiverRequests.forEach(req => {
      if (req.tourId && req.tourName) {
        tourSet.add(JSON.stringify({ id: req.tourId, name: req.tourName }));
      }
    });
    
    const tours = Array.from(tourSet).map(tourStr => JSON.parse(tourStr));
    setUniqueTours(tours);
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setFilteredRequests(waiverRequests);
      return;
    }

    const searchTermLower = searchTerm.toLowerCase();
    const filtered = waiverRequests.filter(waiver => 
      waiver.participantName?.toLowerCase().includes(searchTermLower) ||
      waiver.participantEmail?.toLowerCase().includes(searchTermLower) ||
      waiver.tourName?.toLowerCase().includes(searchTermLower) ||
      waiver.bookingNumber?.toLowerCase().includes(searchTermLower)
    );

    setFilteredRequests(filtered);
  };

  const handleRefresh = () => {
    setSearchTerm('');
    setTourFilter('');
    setStartDateFilter(null);
    setEndDateFilter(null);
    setTourDateStartFilter(null);
    setTourDateEndFilter(null);
    setStatusFilter('all');
    fetchWaiverRequests();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleTourFilterChange = (event) => {
    setTourFilter(event.target.value);
    setPage(0);
  };

  const handleViewDetails = (waiver) => {
    setSelectedWaiver(waiver);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAlertClose = () => {
    setAlertInfo({ ...alertInfo, open: false });
  };

  const paginatedRequests = filteredRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const organizeUpcomingWaivers = () => {
    const today = new Date();
    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(today.getDate() + 7);

    // Filter waivers for the next 7 days
    const upcomingRequests = waiverRequests.filter(req => {
      if (!req.tourDateObj) return false;
      return req.tourDateObj >= today && req.tourDateObj <= sevenDaysFromNow;
    });

    // Filter by selected tours if any are selected
    const filteredRequests = selectedTours.length > 0
      ? upcomingRequests.filter(req => selectedTours.includes(req.tourId))
      : upcomingRequests;

    // Group by date first
    const groupedByDate = {};
    for (let i = 0; i < 7; i++) {
      const date = new Date(today);
      date.setDate(date.getDate() + i);
      const dateStr = format(date, 'yyyy-MM-dd');
      groupedByDate[dateStr] = {
        completed: [],
        uncompleted: []
      };
    }

    // Organize waivers by date and completion status
    filteredRequests.forEach(waiver => {
      if (!waiver.tourDateObj) return;
      
      const dateStr = format(waiver.tourDateObj, 'yyyy-MM-dd');
      if (groupedByDate[dateStr]) {
        if (waiver.status === 'completed') {
          groupedByDate[dateStr].completed.push(waiver);
        } else {
          groupedByDate[dateStr].uncompleted.push(waiver);
        }
      }
    });

    setUpcomingWaivers(groupedByDate);
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    try {
      // If date is already in readable format (e.g. "Saturday, March 1, 2025")
      if (typeof date === 'string' && date.includes(',')) {
        return date;
      }

      // If date is a Firestore Timestamp
      if (date && date._seconds) {
        return format(new Date(date._seconds * 1000), 'EEEE, MMMM d, yyyy');
      }

      // Try parsing as ISO date string
      const parsedDate = parseISO(date);
      if (isValid(parsedDate)) {
        return format(parsedDate, 'EEEE, MMMM d, yyyy');
      }

      // Try creating a Date object directly
      const dateObj = new Date(date);
      if (isValid(dateObj)) {
        return format(dateObj, 'EEEE, MMMM d, yyyy');
      }

      return 'Invalid Date';
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Waiver Management Dashboard
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Tabs value={tabValue} onChange={handleTabChange} centered>
          <Tab label="Overview" icon={<PieChartIcon />} iconPosition="start" />
          <Tab label="Upcoming Tours" icon={<CalendarTodayIcon />} iconPosition="start" />
          <Tab label="Waiver Requests" icon={<ViewListIcon />} iconPosition="start" />
        </Tabs>
      </Box>
      
      {tabValue === 0 && (
        <Box>
          <WaiverStatsCards stats={stats} loading={loading} />
          
          <WaiverCharts 
            stats={stats} 
            tourStats={stats.tourStats} 
            loading={loading} 
          />
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Recent Waiver Requests
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <WaiverTable 
              waivers={waiverRequests.slice(0, 5)} 
              loading={loading}
              onViewWaiver={handleViewDetails}
              onResendEmail={() => {}}
              onMarkCompleted={() => {}}
              onDeleteWaiver={() => {}}
              hasMore={false}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                variant="text" 
                color="primary" 
                onClick={() => setTabValue(1)}
              >
                View All Waivers
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      
      {tabValue === 1 && (
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Select Tours to View
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Tours</InputLabel>
              <Select
                multiple
                value={selectedTours}
                onChange={(e) => setSelectedTours(e.target.value)}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((tourId) => {
                      const tour = uniqueTours.find(t => t.id === tourId);
                      return (
                        <Chip 
                          key={tourId} 
                          label={tour ? tour.name : tourId} 
                          size="small"
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {uniqueTours.map((tour) => (
                  <MenuItem key={tour.id} value={tour.id}>
                    {tour.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom color="success.main">
                  Completed Waivers
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {loading ? (
                  <CircularProgress />
                ) : (
                  Object.entries(upcomingWaivers).map(([dateStr, dateWaivers]) => 
                    dateWaivers.completed.map((waiver) => (
                      <Box key={waiver.id} sx={{ mb: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
                        <Typography variant="subtitle1">
                          {waiver.tourName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Date: {formatDate(waiver.tourDate)}
                        </Typography>
                        <Typography variant="body2">
                          {waiver.participantName} ({waiver.participantEmail})
                        </Typography>
                        <Button
                          size="small"
                          startIcon={<VisibilityIcon />}
                          onClick={() => handleViewDetails(waiver)}
                        >
                          View Details
                        </Button>
                      </Box>
                    ))
                  )
                )}
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2, height: '100%' }}>
                <Typography variant="h6" gutterBottom color="warning.main">
                  Uncompleted Waivers
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {loading ? (
                  <CircularProgress />
                ) : (
                  Object.entries(upcomingWaivers).map(([dateStr, dateWaivers]) => 
                    dateWaivers.uncompleted.map((waiver) => (
                      <Box key={waiver.id} sx={{ mb: 2, p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
                        <Typography variant="subtitle1">
                          {waiver.tourName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Date: {formatDate(waiver.tourDate)}
                        </Typography>
                        <Typography variant="body2">
                          {waiver.participantName} ({waiver.participantEmail})
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                          <Button
                            size="small"
                            startIcon={<VisibilityIcon />}
                            onClick={() => handleViewDetails(waiver)}
                            sx={{ mr: 1 }}
                          >
                            View Details
                          </Button>
                          <Button
                            size="small"
                            startIcon={<EmailIcon />}
                            color="primary"
                            onClick={() => {}}
                          >
                            Resend Email
                          </Button>
                        </Box>
                      </Box>
                    ))
                  )
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      
      {tabValue === 2 && (
        <Box>
          <WaiverFilterBar 
            filters={{
              status: statusFilter,
              tourId: tourFilter,
              startDate: startDateFilter,
              endDate: endDateFilter,
              tourDateStart: tourDateStartFilter,
              tourDateEnd: tourDateEndFilter,
              searchTerm: searchTerm
            }}
            setFilters={(filters) => {
              setStatusFilter(filters.status);
              setTourFilter(filters.tourId);
              setStartDateFilter(filters.startDate);
              setEndDateFilter(filters.endDate);
              setTourDateStartFilter(filters.tourDateStart);
              setTourDateEndFilter(filters.tourDateEnd);
              setSearchTerm(filters.searchTerm);
            }}
            tours={uniqueTours}
            onSearch={handleSearch}
            onClearFilters={() => {
              setStatusFilter('all');
              setTourFilter('');
              setStartDateFilter(null);
              setEndDateFilter(null);
              setTourDateStartFilter(null);
              setTourDateEndFilter(null);
              setSearchTerm('');
            }}
          />
          
          <WaiverTable 
            waivers={filteredRequests.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )}
            loading={loading}
            onViewWaiver={handleViewDetails}
            onResendEmail={() => {}}
            onMarkCompleted={() => {}}
            onDeleteWaiver={() => {}}
            onLoadMore={() => {}}
            hasMore={false}
            defaultSortField={defaultSortField}
            defaultSortDirection={defaultSortDirection}
          />
        </Box>
      )}
      
      <Dialog
        open={detailsOpen}
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Waiver Request Details
        </DialogTitle>
        <DialogContent dividers>
          {selectedWaiver && (
            <WaiverDetailView waiver={selectedWaiver} onClose={handleCloseDetails} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>
      
      <Snackbar 
        open={alertInfo.open} 
        autoHideDuration={6000} 
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleAlertClose} 
          severity={alertInfo.severity} 
          sx={{ width: '100%' }}
        >
          {alertInfo.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default WaiverDashboard; 