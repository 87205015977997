import React, { useEffect } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import Box from '@mui/material/Box';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import SideMenu from './components/SideMenu';
import Footer from './components/FooterDesktop';
import FooterMobile from './components/FooterMobile';
import { OptimizedTourProvider } from './context/OptimizedTourContext';
import { AuthProvider } from './context/AuthContext';
import FBTTPage from './pages/fbtt';
import ActivityManager from './pages/ActivityManager';
import Home from './pages/Home';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SelectedTours from './pages/HomePageTours';
import LocalDeals from './pages/LocalDeals';
import Transfers from './pages/Transfers';
import Contact from './pages/Contact';
import DashboardPage from './pages/Dashboard';
import AdminPage from './pages/Admin';
import WaiversPage from './pages/Waivers';
import WaiverTemplates from './pages/Waivers/WaiverTemplates';
import WaiverForm from './pages/Waivers/WaiverForm';
import WaiverDashboard from './pages/Admin/WaiverDashboard';
import MenuRequests from './pages/Admin/MenuRequests';
import UserManagement from './pages/Admin/UserManagement';
import TemplateTourPage from './components/TemplateTourPage';
import useMediaQuery from '@mui/material/useMediaQuery';
import VideoBanner from './components/VideoBanner';
import RoleBasedRoute from './components/RoleBasedRoute';
import i18n from './components/i18n';
import { I18nextProvider } from 'react-i18next';
import TermsAndConditions from './pages/Terms';
import WhatsAppButton from './components/WhatsApp';
import MobileMenu from './components/MobileMenu';
import ReviewsPage from './pages/reviews';
import TestPage from './pages/TestPage';
import MenuEditor from './pages/MenuEditor/MenuEditor';
import GuestMenu from './pages/GuestMenu/GuestMenu';
import ThankYou from './pages/ThankYou';
import DynamicDashboards from './pages/DynamicDashboards';
import TourMenu from './pages/TourMenu';
import TestComponents from './pages/TestComponents';
import AllTours from './pages/AllTours';
import Categories from './pages/Categories';
import CategoryPage from './pages/CategoryPage';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ScrollToTop from './components/ScrollToTop';


const Layout = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const showSideMenu = false;

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          path: location.pathname,
          title: document.title
        }
      });
    }
  }, [location]);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexGrow: 1,
      flexDirection: isMobile ? 'column' : 'row',
      width: '100%',
      maxWidth: '100%',
    }}>
      {showSideMenu && <SideMenu />}
      <Box component="main" sx={{ 
        flexGrow: 1, 
        width: '100%',
        p: 0,
      }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/all-tours" element={<AllTours />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/categories/:category" element={<CategoryPage />} />
          <Route path="/fbtt" element={<I18nextProvider i18n={i18n}><FBTTPage /></I18nextProvider>} />
          <Route path="/activity-manager" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><ActivityManager /></RoleBasedRoute>} />
          <Route path="/selected-tours" element={<SelectedTours />} />
          <Route path="/tour-info" element={<TemplateTourPage />} />
          <Route path="/tour/:tourId" element={<TemplateTourPage />} />
          <Route path="/local-deals" element={<LocalDeals />} />
          <Route path="/transfers" element={<Transfers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/dashboard" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><DashboardPage /></RoleBasedRoute>} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/admin" element={<RoleBasedRoute allowedRoles={['admin']}><AdminPage /></RoleBasedRoute>} />
          <Route path="/waivers" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><WaiversPage /></RoleBasedRoute>} />
          <Route path="/waiver-form/:waiverId" element={<WaiverForm />} />
          <Route path="/waiver-dashboard" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><WaiverDashboard /></RoleBasedRoute>} />
          <Route path="/reviews" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><ReviewsPage /></RoleBasedRoute>} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/menu-editor" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><MenuEditor /></RoleBasedRoute>} />
          <Route path="/guest-menu" element={<GuestMenu />} />
          <Route path="/tour-menu" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><TourMenu /></RoleBasedRoute>} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/DynamicDashboards" element={<RoleBasedRoute allowedRoles={['admin']}><DynamicDashboards /></RoleBasedRoute>} />
          <Route path="/test-components" element={<TestComponents />} />
          <Route path="/menu-requests" element={<RoleBasedRoute allowedRoles={['admin', 'staff']}><MenuRequests /></RoleBasedRoute>} />
          <Route path="/user-management" element={<RoleBasedRoute allowedRoles={['admin']}><UserManagement /></RoleBasedRoute>} />
          <Route path="/register" element={<RoleBasedRoute allowedRoles={['admin']}><Register /></RoleBasedRoute>} />
        </Routes>
      </Box>
    </Box>
  );
};

const FooterConditional = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  if (isDesktop) return <Footer />;
  if (isMobile) return <FooterMobile />;
  return null;
};

function App() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <OptimizedTourProvider>
            <Router>
              <ScrollToTop />
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                minHeight: '100vh',
                width: '100vw',
                maxWidth: '100vw',
                backgroundColor: '#FFFFFF',
                padding: 0,
                overflow: 'hidden',
                position: 'relative'
              }}>
                <Header />
                {isMobile && <MobileMenu />}
                {/* VideoBanner component temporarily disabled
                  <VideoBanner />
                */}
                <Layout />
                <FooterConditional />
                <WhatsAppButton />
              </Box>
            </Router>
          </OptimizedTourProvider>
        </AuthProvider>
        <Toaster />
      </ThemeProvider>
    </>
  );
}

export default App;