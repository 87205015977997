import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Pending as PendingIcon,
  Error as ErrorIcon,
  Email as EmailIcon
} from '@mui/icons-material';

const StatCard = ({ title, value, icon, color, secondaryValue, secondaryLabel, loading }) => {
  return (
    <Paper sx={{ p: 2, height: '100%', position: 'relative' }}>
      {loading && (
        <LinearProgress 
          sx={{ 
            position: 'absolute', 
            top: 0, 
            left: 0, 
            width: '100%', 
            borderTopLeftRadius: 4, 
            borderTopRightRadius: 4 
          }} 
        />
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Box 
          sx={{ 
            backgroundColor: `${color}.100`, 
            color: `${color}.main`, 
            p: 1, 
            borderRadius: 1, 
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {icon}
        </Box>
        <Typography variant="h6" color="text.secondary">
          {title}
        </Typography>
      </Box>
      <Typography variant="h3" component="div" sx={{ mb: 1 }}>
        {loading ? '-' : value}
      </Typography>
      {secondaryValue !== undefined && (
        <>
          <Divider sx={{ my: 1 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {secondaryLabel}
            </Typography>
            <Typography variant="body1" fontWeight="medium">
              {loading ? '-' : secondaryValue}
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

const WaiverStatsCards = ({ stats, loading }) => {
  const calculateCompletionRate = () => {
    if (stats.total === 0) return '0%';
    return `${stats.completionRate}%`;
  };

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Waivers"
          value={stats.total}
          icon={<EmailIcon />}
          color="primary"
          secondaryValue={stats.lastWeek}
          secondaryLabel="New Requests this week"
          loading={loading}
        />
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Completed"
          value={stats.completed}
          icon={<CheckCircleIcon />}
          color="success"
          secondaryValue={calculateCompletionRate()}
          secondaryLabel="Completion Rate"
          loading={loading}
        />
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Pending"
          value={stats.pending}
          icon={<PendingIcon />}
          color="warning"
          secondaryValue={stats.pendingOlderThan48h}
          secondaryLabel="Needs Attention (>48h)"
          loading={loading}
        />
      </Grid>
      
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Failed"
          value={stats.failed}
          icon={<ErrorIcon />}
          color="error"
          secondaryValue={`${Math.round((stats.failed / stats.total) * 100 || 0)}%`}
          secondaryLabel="Failure Rate"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default WaiverStatsCards; 