import React, { useState } from 'react';
import { 
  Box, 
  Tabs, 
  Tab, 
  Typography, 
  Container, 
  Grid, 
  Card,
  CardActionArea,
  useTheme,
  Paper,
  Avatar,
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';

// Icons
import SettingsIcon from '@mui/icons-material/Settings';
import ReviewIcon from '@mui/icons-material/RateReview';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginIcon from '@mui/icons-material/Login';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PeopleIcon from '@mui/icons-material/People';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ArticleIcon from '@mui/icons-material/Article';
import StarIcon from '@mui/icons-material/Star';
import LaunchIcon from '@mui/icons-material/Launch';

import { useAuth } from '../../context/AuthContext';

// Tab panel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function AdminPage() {
  const { hasRole } = useAuth();
  const isAdmin = hasRole('admin');
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Define all links with categories
  const adminLinks = [
    { 
      path: '/user-management', 
      label: 'User Management', 
      Icon: PeopleIcon,
      description: 'Manage user accounts and roles'
    },
    { 
      path: '/activity-manager', 
      label: 'Activity Manager', 
      Icon: SettingsIcon,
      description: 'Configure tour activities and options'
    },
    { 
      path: '/dashboard', 
      label: 'Analytics', 
      Icon: DashboardIcon,
      description: 'Business metrics and KPIs'
    }
  ];

  const reviewLinks = [
    { 
      path: '/fbtt', 
      label: 'FBTT Reviews', 
      Icon: ReviewIcon,
      description: 'Manage FBTT review forms'
    },
    { 
      path: '/reviews', 
      label: 'Customer Reviews', 
      Icon: StarIcon,
      description: 'Manage customer feedback'
    }
  ];

  const menuLinks = [
    { 
      path: '/menu-editor', 
      label: 'Menu Editor', 
      Icon: RestaurantMenuIcon,
      description: 'Edit food and beverage menus'
    },
    { 
      path: '/menu-requests', 
      label: 'Menu Requests', 
      Icon: FormatListBulletedIcon,
      description: 'View pending menu change requests'
    }
  ];

  const waiverLinks = [
    { 
      path: '/waivers', 
      label: 'Waivers', 
      Icon: DescriptionIcon,
      description: 'Manage customer liability waivers'
    },
    { 
      path: '/waiver-dashboard', 
      label: 'Waiver Dashboard', 
      Icon: AssignmentIcon,
      description: 'Waiver statistics and analysis'
    }
  ];

  const externalLinks = [
    {
      path: 'https://login.trekksoft.com/en/', 
      label: 'Trekksoft Login', 
      Icon: LoginIcon,
      description: 'Access booking management platform',
      isExternal: true
    }
  ];

  // Filter links based on user role
  const displayAdminLinks = isAdmin ? adminLinks : [];
  const displayReviewLinks = isAdmin ? reviewLinks : (hasRole('staff') ? reviewLinks.filter(link => link.path !== '/fbtt') : []);
  const displayMenuLinks = isAdmin || hasRole('staff') ? menuLinks : [];
  const displayWaiverLinks = isAdmin || hasRole('staff') ? waiverLinks : [];
  const displayExternalLinks = isAdmin ? externalLinks : [];

  // Only show tabs that have links to display
  const tabs = [
    { label: 'Admin', icon: <AdminPanelSettingsIcon />, links: displayAdminLinks },
    { label: 'Reviews', icon: <StarIcon />, links: displayReviewLinks },
    { label: 'Menus', icon: <RestaurantIcon />, links: displayMenuLinks },
    { label: 'Waivers', icon: <ArticleIcon />, links: displayWaiverLinks },
    { label: 'Trekksoft', icon: <LaunchIcon />, links: displayExternalLinks }
  ].filter(tab => tab.links.length > 0);

  // Render a single card
  const renderCard = (link) => (
    <Card 
      elevation={2}
      sx={{ 
        height: '100%',
        transition: 'all 0.2s',
        '&:hover': { 
          transform: 'translateY(-4px)',
          boxShadow: 4
        },
        borderRadius: 2
      }}
    >
      {link.isExternal ? (
        <CardActionArea 
          component="a"
          href={link.path}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3
          }}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.main,
              width: 64,
              height: 64,
              mb: 2
            }}
          >
            <link.Icon sx={{ fontSize: 32 }} />
          </Avatar>
          <Typography variant="h6" align="center" gutterBottom>
            {link.label}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {link.description}
          </Typography>
        </CardActionArea>
      ) : (
        <CardActionArea 
          component={Link} 
          to={link.path}
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 3
          }}
        >
          <Avatar
            sx={{
              bgcolor: theme.palette.primary.main,
              width: 64,
              height: 64,
              mb: 2
            }}
          >
            <link.Icon sx={{ fontSize: 32 }} />
          </Avatar>
          <Typography variant="h6" align="center" gutterBottom>
            {link.label}
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            {link.description}
          </Typography>
        </CardActionArea>
      )}
    </Card>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
      <Paper 
        elevation={2} 
        sx={{ 
          p: 3, 
          mb: 4, 
          borderRadius: 2,
          background: theme.palette.primary.main,
          color: '#fff'
        }}
      >
        <Typography variant="h4" component="h1" align="center" fontWeight="bold">
          Admin Dashboard
        </Typography>
      </Paper>
      
      <Paper elevation={3} sx={{ borderRadius: 2 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            '& .MuiTab-root': {
              py: 2
            }
          }}
        >
          {tabs.map((tab, index) => (
            <Tab 
              key={index} 
              label={tab.label} 
              icon={tab.icon} 
              iconPosition="start" 
            />
          ))}
        </Tabs>

        {tabs.map((tab, index) => (
          <TabPanel key={index} value={tabValue} index={index}>
            <Grid container spacing={3}>
              {tab.links.map((link, linkIndex) => (
                <Grid item xs={12} sm={6} md={4} key={linkIndex}>
                  {renderCard(link)}
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        ))}
      </Paper>
    </Container>
  );
}

export default AdminPage;