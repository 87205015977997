import React, { useState } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Grid,
  Paper,
  Typography,
  IconButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Chip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Search as SearchIcon
} from '@mui/icons-material';

const WaiverFilterBar = ({ 
  filters, 
  setFilters, 
  tours, 
  onSearch,
  onClearFilters 
}) => {
  const [expanded, setExpanded] = useState(false);
  const [localFilters, setLocalFilters] = useState(filters);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleFilterChange = (field, value) => {
    setLocalFilters(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleApplyFilters = () => {
    setFilters(localFilters);
    onSearch(localFilters);
  };

  const handleClearFilters = () => {
    const clearedFilters = {
      status: 'all',
      tourId: 'all',
      startDate: null,
      endDate: null,
      tourDateStart: null,
      tourDateEnd: null,
      searchTerm: ''
    };
    setLocalFilters(clearedFilters);
    setFilters(clearedFilters);
    onClearFilters();
  };

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FilterListIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Filters</Typography>
        </Box>
        <Box>
          <IconButton onClick={handleToggleExpand}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Search by name, email, or booking number"
              variant="outlined"
              value={localFilters.searchTerm}
              onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton 
                    edge="end" 
                    onClick={handleApplyFilters}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleApplyFilters();
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleApplyFilters}
                sx={{ mr: 1 }}
              >
                Apply Filters
              </Button>
              <Button 
                variant="outlined" 
                onClick={handleClearFilters}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Collapse in={expanded}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={localFilters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                label="Status"
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Tour</InputLabel>
              <Select
                value={localFilters.tourId}
                onChange={(e) => handleFilterChange('tourId', e.target.value)}
                label="Tour"
              >
                <MenuItem value="all">All Tours</MenuItem>
                {tours.map((tour) => (
                  <MenuItem key={tour.id} value={tour.id}>
                    {tour.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Created Start Date"
                value={localFilters.startDate}
                onChange={(date) => handleFilterChange('startDate', date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="MM/dd/yyyy"
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Created End Date"
                value={localFilters.endDate}
                onChange={(date) => handleFilterChange('endDate', date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="MM/dd/yyyy"
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
              Filter by Tour Date
            </Typography>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Tour Date - From"
                value={localFilters.tourDateStart}
                onChange={(date) => handleFilterChange('tourDateStart', date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="MM/dd/yyyy"
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Tour Date - To"
                value={localFilters.tourDateEnd}
                onChange={(date) => handleFilterChange('tourDateEnd', date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="MM/dd/yyyy"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {Object.entries(localFilters).some(([key, value]) => 
          key !== 'searchTerm' && value && value !== 'all'
        ) && (
          <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Typography variant="subtitle2" sx={{ mr: 1 }}>Active Filters:</Typography>
            {localFilters.status !== 'all' && (
              <Chip 
                label={`Status: ${localFilters.status}`} 
                onDelete={() => handleFilterChange('status', 'all')}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            {localFilters.tourId !== 'all' && (
              <Chip 
                label={`Tour: ${tours.find(t => t.id === localFilters.tourId)?.name || localFilters.tourId}`} 
                onDelete={() => handleFilterChange('tourId', 'all')}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            {localFilters.startDate && (
              <Chip 
                label={`Created From: ${localFilters.startDate.toLocaleDateString()}`} 
                onDelete={() => handleFilterChange('startDate', null)}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            {localFilters.endDate && (
              <Chip 
                label={`Created To: ${localFilters.endDate.toLocaleDateString()}`} 
                onDelete={() => handleFilterChange('endDate', null)}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            {localFilters.tourDateStart && (
              <Chip 
                label={`Tour From: ${localFilters.tourDateStart.toLocaleDateString()}`} 
                onDelete={() => handleFilterChange('tourDateStart', null)}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
            {localFilters.tourDateEnd && (
              <Chip 
                label={`Tour To: ${localFilters.tourDateEnd.toLocaleDateString()}`} 
                onDelete={() => handleFilterChange('tourDateEnd', null)}
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
          </Box>
        )}
      </Collapse>
    </Paper>
  );
};

export default WaiverFilterBar; 