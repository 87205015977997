import React, { useState, useEffect, useCallback } from 'react';
import { Card, Typography, Button, Box, Divider, Grid, TextField, IconButton, CircularProgress, Select, MenuItem, FormControl, InputLabel, Container, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { collection, addDoc, getDocs, query, orderBy, doc, getDoc, updateDoc, where } from 'firebase/firestore';
import { db } from '../../config/firebase/firebase';
import Menuform from './Menuform';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const prefilledData = [
  // Culinary Tour restaurants
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Appetizer', option: 'Mini beef pastechi', description: 'Local pastry filled with seasoned beef' },
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Appetizer', option: 'Coconut Rice', description: 'Rice cooked in coconut milk with local spices' },
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Appetizer', option: 'Pumpkin Soup', description: 'Creamy soup made with local pumpkin' },
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Cocktail', option: 'Mosa Nena', description: 'Local cocktail with rum and tropical fruits' },
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Cocktail', option: 'Buchi Fil', description: 'Traditional blue curaçao cocktail' },
  { tour: 'Culinary Tour', restaurant: 'Rustiq', category: 'Cocktail', option: 'Kura di Shon', description: 'Signature cocktail with local ingredients' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Fettucini Truffle', description: 'Pasta with truffle cream sauce' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Cuban Banana Soup (Creamy, Slightly spicy)', description: 'Rich soup made with plantains and local spices' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Canneloni (Salmon, Spinach, Ricotta)', description: 'Pasta rolls filled with salmon, spinach and ricotta cheese' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Karni Stobá', description: 'Traditional beef stew slow-cooked with local herbs and vegetables' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Antillian Beefstew', description: 'Local style beef stew with Caribbean spices and vegetables' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Main Course', option: 'Caribbean Chicken (Fillet marinated with spices, Mango Chutney)', description: 'Chicken fillet marinated in island spices served with mango chutney' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Drink', option: 'Soft drink', description: '' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Drink', option: 'Juice', description: 'Fresh tropical fruit juice' },
  { tour: 'Culinary Tour', restaurant: 'Gouverneur', category: 'Drink', option: 'Water / Sparkling water', description: '' },
  { tour: 'Culinary Tour', restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'Quesillo', description: 'Traditional Curaçaoan caramel flan' },
  { tour: 'Culinary Tour', restaurant: 'Gondola Restaurant', category: 'Dessert', option: 'With Tea or Coffee', description: '' },
  // Beach Escape tour
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Food', option: 'Caesar Salad', description: 'Classic salad with romaine lettuce, croutons and Caesar dressing' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Food', option: 'Burger', description: 'Beef patty with lettuce, tomato and special sauce' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Food', option: 'Cheese Burger', description: 'Beef patty with cheese, lettuce, tomato and special sauce' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Food', option: 'Chicken Sate', description: 'Grilled chicken skewers with peanut sauce' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Drinks', option: 'Cola', description: '' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Drinks', option: 'Sprite', description: '' },
  { tour: 'Beach Escape', restaurant: 'Beach Escape', category: 'Drinks', option: 'Water', description: '' },
  // Island Tour
  { tour: 'Island Tour', restaurant: 'Island Tour', category: 'Food', option: 'Truffle Tagliatelle (V)', description: 'Vegetarian pasta with truffle sauce' },
  { tour: 'Island Tour', restaurant: 'Island Tour', category: 'Food', option: 'Karni Stobá', description: 'Traditional Curaçaoan beef stew slow-cooked with local vegetables and spices' },
  { tour: 'Island Tour', restaurant: 'Island Tour', category: 'Food', option: 'Galiña Stobá', description: 'Traditional Curaçaoan chicken stew with local vegetables and herbs' },
  { tour: 'Island Tour', restaurant: 'Island Tour', category: 'Food', option: 'Vegie Stobá', description: 'Vegetarian version of the traditional Curaçaoan stew with local vegetables' },
];

function MenuEditor() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [guestName, setGuestName] = useState('');
  const [menuItems, setMenuItems] = useState([]);
  const [savedMenu, setSavedMenu] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState('');
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newTour, setNewTour] = useState('');
  const [showAddTour, setShowAddTour] = useState(false);
  
  // New state for edit mode
  const [isEditMode, setIsEditMode] = useState(false);
  const [editMenuId, setEditMenuId] = useState('');
  const [showMenuSelector, setShowMenuSelector] = useState(false);
  const [existingMenus, setExistingMenus] = useState([]);
  const [menuFilter, setMenuFilter] = useState('');

  useEffect(() => {
    // Set up initial data for tours
    const uniqueTours = [...new Set(prefilledData.map(item => item.tour))];
    setTours(uniqueTours);
    
    // Load existing menus from Firestore
    const loadExistingMenus = async () => {
      try {
        const menusQuery = query(collection(db, 'menus'), orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(menusQuery);
        
        const menus = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // Format date for display
          createdAt: doc.data().createdAt?.toDate?.() || new Date(),
          tourType: doc.data().tourType || doc.data().tourName || 'Unknown'
        }));
        
        setExistingMenus(menus);
      } catch (error) {
        console.error('Error loading existing menus:', error);
      }
    };
    
    loadExistingMenus();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedTour) {
      // Get restaurants for the selected tour
      const tourRestaurants = [...new Set(prefilledData
        .filter(item => item.tour === selectedTour)
        .map(item => item.restaurant))];
      setRestaurants(tourRestaurants);
      
      // If there's only one restaurant (like for Beach Escape or Island Tour),
      // select it automatically
      if (tourRestaurants.length === 1) {
        setSelectedRestaurant(tourRestaurants[0]);
      } else {
        setSelectedRestaurant('');
      }
      
      // Reset other selections
      setCategories([]);
      setSelectedCategory('');
      setMenuItems([]);
    }
  }, [selectedTour]);

  useEffect(() => {
    if (selectedTour && selectedRestaurant) {
      // Get categories for the selected tour and restaurant
      const restaurantCategories = [...new Set(prefilledData
        .filter(item => item.tour === selectedTour && item.restaurant === selectedRestaurant)
        .map(item => item.category))];
      setCategories(restaurantCategories);
      setSelectedCategory('');
      setMenuItems([]);
    }
  }, [selectedTour, selectedRestaurant]);

  useEffect(() => {
    if (selectedTour && selectedRestaurant && selectedCategory) {
      const categoryItems = prefilledData
        .filter(item => 
          item.tour === selectedTour && 
          item.restaurant === selectedRestaurant && 
          item.category === selectedCategory
        )
        .map(item => ({ name: item.option, description: item.description || '' }));
      setMenuItems(categoryItems);
    }
  }, [selectedTour, selectedRestaurant, selectedCategory]);

  const handleAddItem = () => {
    setMenuItems([...menuItems, { name: '' }]);
  };

  const handleRemoveItem = (index) => {
    const newMenuItems = menuItems.filter((_, i) => i !== index);
    setMenuItems(newMenuItems);
  };

  const handleItemChange = (index, field, value) => {
    const newMenuItems = [...menuItems];
    newMenuItems[index][field] = value;
    setMenuItems(newMenuItems);
  };

  const handleSaveMenu = async () => {
    if (!selectedTour || !selectedRestaurant || !selectedCategory || menuItems.length === 0) {
      alert('Please complete all fields and add at least one menu item');
      return;
    }
    
    try {
      const menuData = {
        tourType: selectedTour,
        restaurantName: selectedRestaurant,
        category: selectedCategory,
        guestName,
        menuItems,
        createdAt: new Date()
      };
      
      if (isEditMode && editMenuId) {
        // Update existing menu
        await updateDoc(doc(db, 'menus', editMenuId), menuData);
        alert('Menu updated successfully!');
      } else {
        // Create new menu
        await addDoc(collection(db, 'menus'), menuData);
        alert('Menu saved successfully!');
      }
      
      setSavedMenu(menuData);
      
      // Refresh existing menus list
      const menusQuery = query(collection(db, 'menus'), orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(menusQuery);
      const menus = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.() || new Date(),
        tourType: doc.data().tourType || doc.data().tourName || 'Unknown'
      }));
      setExistingMenus(menus);
      
      // Reset edit mode
      setIsEditMode(false);
      setEditMenuId('');
      
    } catch (error) {
      console.error('Error saving menu: ', error);
      alert('Error saving menu. Please try again.');
    }
  };

  const handleAddNewTour = () => {
    if (newTour.trim()) {
      setTours(prev => [...prev, newTour.trim()]);
      setSelectedTour(newTour.trim());
      setNewTour('');
      setShowAddTour(false);
    }
  };
  
  const handleOpenExistingMenus = () => {
    setShowMenuSelector(true);
  };
  
  const handleCloseExistingMenus = () => {
    setShowMenuSelector(false);
    setMenuFilter('');
  };
  
  const handleSelectExistingMenu = async (id) => {
    try {
      setLoading(true);
      const menuDoc = await getDoc(doc(db, 'menus', id));
      
      if (menuDoc.exists()) {
        const data = menuDoc.data();
        
        // Load the menu data into the form
        setSelectedTour(data.tourType || data.tourName || '');
        setSelectedRestaurant(data.restaurantName || '');
        setSelectedCategory(data.category || '');
        setGuestName(data.guestName || '');
        setMenuItems(data.menuItems || []);
        
        // Set edit mode
        setIsEditMode(true);
        setEditMenuId(id);
        
        // Close dialog
        setShowMenuSelector(false);
        setMenuFilter('');
      } else {
        alert('Menu not found');
      }
    } catch (error) {
      console.error('Error loading menu:', error);
      alert('Error loading menu. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  const filteredMenus = menuFilter
    ? existingMenus.filter(menu => 
        menu.tourType?.toLowerCase().includes(menuFilter.toLowerCase()) ||
        menu.restaurantName?.toLowerCase().includes(menuFilter.toLowerCase()) ||
        menu.category?.toLowerCase().includes(menuFilter.toLowerCase())
      )
    : existingMenus;

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress sx={{ color: theme.brandColors.blue }} />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box sx={{ 
        py: { xs: 3, sm: 4, md: 5 },
        px: { xs: 2, sm: 3, md: 4 },
        minHeight: '100vh',
        background: 'linear-gradient(180deg, rgba(26,61,143,0.02) 0%, rgba(26,61,143,0.05) 100%)',
      }}>
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 3, md: 4, lg: 6 }}>
            <Grid item xs={12} lg={6}>
              <Card sx={{
                background: 'linear-gradient(180deg, rgba(26,61,143,0.05) 0%, rgba(26,61,143,0.1) 100%)',
                borderRadius: { xs: 2, md: 3 },
                overflow: 'hidden',
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
              }}>
                <Box sx={{ 
                  p: { xs: 2.5, sm: 3, md: 4 },
                  pb: { xs: 1, sm: 1.5, md: 2 }
                }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: { xs: 3, md: 4 } }}>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        color: theme.brandColors.blue,
                        fontWeight: 800,
                        fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' },
                        letterSpacing: '-0.02em',
                      }}
                    >
                      {isEditMode ? 'Edit Menu' : 'Menu Editor'}
                    </Typography>
                    
                    <Button 
                      variant="outlined"
                      onClick={handleOpenExistingMenus}
                      sx={{
                        color: theme.brandColors.blue,
                        borderColor: theme.brandColors.blue,
                        '&:hover': {
                          borderColor: theme.brandColors.blue,
                          backgroundColor: 'rgba(26,61,143,0.05)'
                        }
                      }}
                    >
                      <EditIcon sx={{ mr: 1 }} />
                      Edit Existing
                    </Button>
                  </Box>
              
                  <Box sx={{ mb: { xs: 3, md: 4 } }}>
                    <Box sx={{ mb: 2 }}>
                      {!showAddTour ? (
                        <FormControl fullWidth sx={{ mb: 2.5 }}>
                          <InputLabel sx={{ 
                            color: theme.brandColors.blue,
                            fontSize: { xs: '0.9rem', md: '1rem' }
                          }}>
                            Select Tour
                          </InputLabel>
                          <Select
                            value={selectedTour}
                            onChange={(e) => setSelectedTour(e.target.value)}
                            sx={{
                              fontSize: { xs: '0.9rem', md: '1rem' },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.brandColors.blue,
                                borderWidth: '1.5px'
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.brandColors.blue,
                                borderWidth: '2px'
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.brandColors.blue,
                                borderWidth: '2px'
                              }
                            }}
                          >
                            {tours.map((tour) => (
                              <MenuItem 
                                key={tour} 
                                value={tour}
                                sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}
                              >
                                {tour}
                              </MenuItem>
                            ))}
                            {/* Add tour types that might exist in database but not in prefilled data */}
                            <MenuItem value="beach-escape">Beach Escape</MenuItem>
                            <MenuItem value="island-tour">Island Tour</MenuItem>
                            <MenuItem value="culinary-tour">Culinary Tour</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <Box sx={{ display: 'flex', gap: 1, mb: 2.5 }}>
                          <TextField
                            fullWidth
                            label="New Tour Name"
                            value={newTour}
                            onChange={(e) => setNewTour(e.target.value)}
                            sx={{ 
                              '& .MuiOutlinedInput-root': {
                                fontSize: { xs: '0.9rem', md: '1rem' },
                                '& fieldset': {
                                  borderColor: theme.brandColors.blue,
                                  borderWidth: '1.5px'
                                },
                                '&:hover fieldset': {
                                  borderColor: theme.brandColors.blue,
                                  borderWidth: '2px'
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: theme.brandColors.blue,
                                  borderWidth: '2px'
                                }
                              },
                              '& .MuiInputLabel-root': {
                                color: theme.brandColors.blue,
                                fontSize: { xs: '0.9rem', md: '1rem' }
                              }
                            }}
                          />
                          <Button
                            variant="contained"
                            onClick={handleAddNewTour}
                            disabled={!newTour.trim()}
                            sx={{
                              backgroundColor: theme.brandColors.yellow,
                              color: theme.brandColors.blue,
                              fontWeight: 700,
                              minWidth: '120px',
                              '&:hover': {
                                backgroundColor: theme.brandColors.lightYellow,
                              }
                            }}
                          >
                            Add
                          </Button>
                        </Box>
                      )}
                      <Button
                        onClick={() => setShowAddTour(!showAddTour)}
                        sx={{
                          color: theme.brandColors.blue,
                          textTransform: 'none',
                          fontSize: '0.9rem',
                          '&:hover': {
                            backgroundColor: 'rgba(26,61,143,0.05)'
                          }
                        }}
                      >
                        {showAddTour ? '← Back to Selection' : '+ Add New Tour'}
                      </Button>
                    </Box>

                    {selectedTour && restaurants.length > 1 && (
                      <FormControl fullWidth sx={{ mb: 2.5 }}>
                        <InputLabel sx={{ 
                          color: theme.brandColors.blue,
                          fontSize: { xs: '0.9rem', md: '1rem' }
                        }}>
                          Select Restaurant
                        </InputLabel>
                        <Select
                          value={selectedRestaurant}
                          onChange={(e) => setSelectedRestaurant(e.target.value)}
                          sx={{
                            fontSize: { xs: '0.9rem', md: '1rem' },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '1.5px'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '2px'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '2px'
                            }
                          }}
                        >
                          {restaurants.map((restaurant) => (
                            <MenuItem 
                              key={restaurant} 
                              value={restaurant}
                              sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}
                            >
                              {restaurant}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    {selectedTour && selectedRestaurant && (
                      <FormControl fullWidth sx={{ mb: 2.5 }}>
                        <InputLabel sx={{ 
                          color: theme.brandColors.blue,
                          fontSize: { xs: '0.9rem', md: '1rem' }
                        }}>
                          Select Category
                        </InputLabel>
                        <Select
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          sx={{
                            fontSize: { xs: '0.9rem', md: '1rem' },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '1.5px'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '2px'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.brandColors.blue,
                              borderWidth: '2px'
                            }
                          }}
                        >
                          {categories.map((category) => (
                            <MenuItem 
                              key={category} 
                              value={category}
                              sx={{ fontSize: { xs: '0.9rem', md: '1rem' } }}
                            >
                              {category}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <TextField
                      fullWidth
                      label="Guest Name"
                      value={guestName}
                      onChange={(e) => setGuestName(e.target.value)}
                      sx={{ 
                        '& .MuiOutlinedInput-root': {
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          '& fieldset': {
                            borderColor: theme.brandColors.blue,
                            borderWidth: '1.5px'
                          },
                          '&:hover fieldset': {
                            borderColor: theme.brandColors.blue,
                            borderWidth: '2px'
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: theme.brandColors.blue,
                            borderWidth: '2px'
                          }
                        },
                        '& .MuiInputLabel-root': {
                          color: theme.brandColors.blue,
                          fontSize: { xs: '0.9rem', md: '1rem' }
                        },
                      }}
                    />
                  </Box>

                  <Divider sx={{ 
                    my: { xs: 3, md: 4 }, 
                    borderColor: theme.brandColors.blue,
                    opacity: 0.3 
                  }} />

                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      mb: { xs: 2.5, md: 3 },
                      color: theme.brandColors.blue,
                      fontWeight: 600,
                      fontSize: { xs: '1.1rem', md: '1.2rem' },
                      textAlign: { xs: 'center', sm: 'left' }
                    }}
                  >
                    Menu Items:
                  </Typography>

                  <Box sx={{ mb: { xs: 2.5, md: 3 } }}>
                    {menuItems.map((item, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        <Grid container spacing={{ xs: 1.5, sm: 2 }} sx={{ mb: 2 }}>
                          <Grid item xs={10}>
                            <TextField
                              fullWidth
                              label="Item Name"
                              value={item.name}
                              onChange={(e) => handleItemChange(index, 'name', e.target.value)}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  fontSize: { xs: '0.9rem', md: '1rem' },
                                  '& fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '1.5px'
                                  },
                                  '&:hover fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '2px'
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '2px'
                                  }
                                },
                                '& .MuiInputLabel-root': {
                                  color: theme.brandColors.blue,
                                  fontSize: { xs: '0.9rem', md: '1rem' }
                                },
                              }}
                            />
                            <TextField
                              fullWidth
                              label="Description (optional)"
                              value={item.description || ''}
                              onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                              size="small"
                              margin="dense"
                              placeholder="Explain local dish for guests (optional)"
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  fontSize: { xs: '0.85rem', md: '0.9rem' },
                                  '& fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '1px'
                                  },
                                  '&:hover fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '1px'
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: theme.brandColors.blue,
                                    borderWidth: '2px'
                                  }
                                },
                                '& .MuiInputLabel-root': {
                                  color: theme.brandColors.blue,
                                  fontSize: { xs: '0.85rem', md: '0.9rem' }
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={2} sx={{ 
                            display: 'flex', 
                            justifyContent: { xs: 'flex-start', sm: 'center' }
                          }}>
                            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                              <IconButton 
                                onClick={() => handleRemoveItem(index)}
                                sx={{ 
                                  color: theme.brandColors.blue,
                                  '&:hover': {
                                    backgroundColor: 'rgba(26,61,143,0.1)',
                                  }
                                }}
                              >
                                <DeleteIcon sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }} />
                              </IconButton>
                            </motion.div>
                          </Grid>
                        </Grid>
                      </motion.div>
                    ))}
                  </Box>

                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: { xs: 'center', sm: 'flex-start' },
                    mb: { xs: 2.5, md: 3 }
                  }}>
                    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                      <Button 
                        startIcon={<AddIcon sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }} />} 
                        onClick={handleAddItem} 
                        variant="outlined"
                        sx={{
                          color: theme.brandColors.blue,
                          borderColor: theme.brandColors.blue,
                          borderWidth: '1.5px',
                          fontSize: { xs: '0.9rem', md: '1rem' },
                          px: { xs: 2, md: 3 },
                          py: { xs: 0.75, md: 1 },
                          '&:hover': {
                            borderColor: theme.brandColors.blue,
                            borderWidth: '1.5px',
                            backgroundColor: 'rgba(26,61,143,0.1)',
                          }
                        }}
                      >
                        Add Item
                      </Button>
                    </motion.div>
                  </Box>
                </Box>

                <Box sx={{ 
                  p: { xs: 2.5, sm: 3, md: 4 }, 
                  display: 'flex', 
                  justifyContent: 'flex-end',
                  backgroundColor: 'rgba(26,61,143,0.1)',
                  borderTop: '1px solid rgba(26,61,143,0.2)'
                }}>
                  <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                    <Button
                      variant="contained"
                      onClick={handleSaveMenu}
                      sx={{
                        backgroundColor: theme.brandColors.yellow,
                        color: theme.brandColors.blue,
                        fontWeight: 700,
                        px: { xs: 3, md: 4 },
                        py: { xs: 1, md: 1.5 },
                        fontSize: { xs: '0.9rem', md: '1rem' },
                        borderRadius: 2,
                        textTransform: 'none',
                        boxShadow: '0 4px 14px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          backgroundColor: theme.brandColors.lightYellow,
                          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
                        }
                      }}
                    >
                      {isEditMode ? 'Update Menu' : 'Save Menu'}
                    </Button>
                  </motion.div>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Menuform savedMenu={savedMenu} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Dialog for selecting existing menus to edit */}
      <Dialog 
        open={showMenuSelector} 
        onClose={handleCloseExistingMenus}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ 
          backgroundColor: theme.brandColors.blue, 
          color: 'white',
          fontWeight: 600
        }}>
          Select Menu to Edit
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          <TextField
            fullWidth
            label="Filter Menus"
            value={menuFilter}
            onChange={(e) => setMenuFilter(e.target.value)}
            sx={{ mb: 2 }}
            placeholder="Enter tour type, restaurant or category..."
          />
          
          <List sx={{ maxHeight: '500px', overflow: 'auto' }}>
            {filteredMenus.length > 0 ? (
              filteredMenus.map((menu) => (
                <ListItem 
                  key={menu.id}
                  button
                  onClick={() => handleSelectExistingMenu(menu.id)}
                  sx={{ 
                    border: '1px solid #e0e0e0',
                    borderRadius: '4px',
                    mb: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(26,61,143,0.05)'
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.brandColors.blue }}>
                        {menu.tourType} - {menu.restaurantName} - {menu.category}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="body2" component="span">
                          {menu.menuItems?.length || 0} items
                        </Typography>
                        <Typography variant="body2" component="span" sx={{ ml: 2, color: 'text.secondary' }}>
                          Created: {menu.createdAt?.toLocaleDateString?.() || 'Unknown'}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <Typography sx={{ textAlign: 'center', color: 'text.secondary', py: 4 }}>
                No menus found
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseExistingMenus} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
}

export default MenuEditor;